import React from "react"
import { Link } from "gatsby"
import Container from "../components/container.js"
import SEO from "../components/SEO"

export default () => (
    <Container>
        <SEO title="ブランディングの本 『ニューヨークのアートディレクターがいま、日本のビジネスリーダーに伝えたいこと』をオンラインで購入" />
        <header>
            <nav className='anotherHeader'>
                <Link to="/" className='off'><div className='backToTopSign'></div></Link>
                <Link to="/" className='hoverOff'>ホーム</Link>
            </nav>
        </header>
        <section id='stores' className='area'>
            <h1 className='headline'>オンラインで購入</h1>
            <p>『ニューヨークのアートディレクターがいま、日本のビジネスリーダーに伝えたいこと ー世界に通用するデザイン経営戦略』はブランディングの本です。<br />
                アートディレクターの著者がカバーデザインだけでなく本文デザインにもこだわって作成しているため、紙の本がおすすめです。<br />
                本文デザインが反映されませんが内容は同じですので、お急ぎの方は電子書籍版をどうぞ。
            </p>
            <a href="https://aoyamabc.jp/collections/business/products/newyorknoartdirectorhgaima" target="_blank" rel="noopener noreferrer" className="external">
                青山ブックセンターオンライン
            </a><br />
            <a href="https://www.kinokuniya.co.jp/f/dsg-01-9784295402954" target="_blank" rel="noopener noreferrer" className="external">
                紀伊國屋書店ウェブストア
            </a><br />
            <a href="http://shop.tsutaya.co.jp/book/product/9784295402954/" target="_blank" rel="noopener noreferrer" className="external">
                TSUTAYA オンライン
            </a><br />
            <a href="https://honto.jp/netstore/pd-book_29601606.html" target="_blank" rel="noopener noreferrer" className="external">
                honto(丸善ジュンク堂、文教堂等)
            </a><br />
            <a href="https://paypaymall.yahoo.co.jp/store/books-ogaki/item/9784295402954/" target="_blank" rel="noopener noreferrer" className="external">
                京都 大垣書店オンライン
            </a><br />
            <a href="https://www.e-hon.ne.jp/bec/SA/Detail?refShinCode=0100000000000033915872&Action_id=121&Sza_id=B0" target="_blank" rel="noopener noreferrer" className="external">
                e-hon(近所の書店で購入)
            </a><br />
            <a href="https://www.honyaclub.com/shop/g/g19595042/" target="_blank" rel="noopener noreferrer" className="external">
                Honya Club(近所の書店で購入)
            </a><br />
            <a href="https://www.amazon.co.jp/dp/4295402958" target="_blank" rel="noopener noreferrer" className="external">
                Amazon
            </a><br />
            <a href="https://books.rakuten.co.jp/rb/15868461/" target="_blank" rel="noopener noreferrer" className="external">
                楽天ブックス
            </a><br />
            <a href="https://7net.omni7.jp/detail/1106978536" target="_blank" rel="noopener noreferrer" className="external">
                セブンネットショッピング
            </a><br />
            <a href="https://www.hmv.co.jp/artist_%E5%B0%8F%E5%B1%B1%E7%94%B0%E8%82%B2_000000000798978/item_%E3%83%8B%E3%83%A5%E3%83%BC%E3%83%A8%E3%83%BC%E3%82%AF%E3%81%AE%E3%82%A2%E3%83%BC%E3%83%88%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%82%BF%E3%83%BC%E3%81%8C%E3%81%84%E3%81%BE%E3%80%81%E6%97%A5%E6%9C%AC%E3%81%AE%E3%83%93%E3%82%B8%E3%83%8D%E3%82%B9%E3%83%AA%E3%83%BC%E3%83%80%E3%83%BC%E3%81%AB%E4%BC%9D%E3%81%88%E3%81%9F%E3%81%84%E3%81%93%E3%81%A8-%E4%B8%96%E7%95%8C%E3%81%AB%E9%80%9A%E7%94%A8%E3%81%99%E3%82%8B%E3%83%87%E3%82%B6%E3%82%A4%E3%83%B3%E7%B5%8C%E5%96%B6%E6%88%A6%E7%95%A5_9820849" target="_blank" rel="noopener noreferrer" className="external">
                HMV&BOOKS online
            </a><br />
            <a href="https://www.yodobashi.com/product/100000009003125693/" target="_blank" rel="noopener noreferrer" className="external">
                ヨドバシドットコム書籍
            </a><br />
            <br />
            <Link to="/" className="external return">ホームに戻る</Link>
        </section>
    </Container>
)
